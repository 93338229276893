import * as moment from 'moment';
export class TimeFormater {
    static timeFormatter(params) {
        if (params.value) {
          debugger;
          let d = new Date(params.value);
          return moment(d).format("DD/MM/YYYY HH:mm")
        }
        else {
          ''
        }
        ;
      }
}
