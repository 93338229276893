import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  constructor(private router: Router) { }
  piscines;
  selectedPiscine= {};
  typeReservations;
  typeEntrees;
  model: NgModel;
  email;
  total = 0;
  entreeP0 = 0;
  entreeR0 = 0;
  entreeP1 = 0;
  entreeR1 = 0;
  entreeP2 = 0;
  entreeR2 = 0;
  entreeP3 = 0;
  entreeR3 = 0;
  ngOnInit() {
    this.piscines = [
      {id:1,label:'Saint-Charles - 1er'},
  {id:2,label:'Vallier - 4e'},
  {id:3,label:'Bonneveine - 8e'},
  {id:4,label:'Pointe Rouge - 8e'},
  {id:5,label:'Desautel - 9e'},
  {id:6,label:'Pont-de-Vivaux - 10e'},
  {id:7,label:'La Granière - 11e'},
  {id:8,label:'Bombardière - 12e'},
  {id:9,label:'Louis Armand - 12e'},
  {id:10,label:'Frais Vallon - 13e'},
  {id:11,label:'Saint Joseph - 14e'},
  {id:12,label:'Busserine - 14e'},
  {id:13,label:'La Martine - 15e'},
  {id:14,label:'La Castellane - 16e'},
  {id:15,label:'Magnac - 8e'}];
  this.typeEntrees=[
    {id:1,label:'ENTRÉE A L’UNITÉ',pleinTarif:3,tarifReduit:1.5},
    {id:2,label:'CARTE 10 ENTRÉES',pleinTarif:20,tarifReduit:10},
    {id:3,label:'ABONNEMENT ANNUEL',pleinTarif:161,tarifReduit:84},
    {id:4,label:'GRATUITÉ',pleinTarif:0,tarifReduit:0}
  ];
  this.typeReservations=[
    {id:1,label:'ENTRÉES'},
    {id:2,label:'LEÇONS'}
  ];
  
  }
  findSum(){
    debugger;
    let tmp =0;
    if(this.entreeP0 && this.entreeP0 > 0){
      tmp = this.typeEntrees[0].pleinTarif * this.entreeP0;
    }
    if(this.entreeR0 && this.entreeR0 > 0){
      tmp = tmp + this.typeEntrees[0].tarifReduit * this.entreeR0;
    }
    if(this.entreeP1 && this.entreeP1 > 0){
      tmp = tmp + this.typeEntrees[1].pleinTarif * this.entreeP1;
    }
    if(this.entreeR1 && this.entreeR1 > 0){
      tmp = tmp + this.typeEntrees[1].tarifReduit * this.entreeR1;
    }
    if(this.entreeP2 && this.entreeP2 > 0){
      tmp = tmp +  this.typeEntrees[2].pleinTarif * this.entreeP2;
    }
    if(this.entreeR2 && this.entreeR2 > 0){
      tmp = tmp +  this.typeEntrees[2].tarifReduit * this.entreeR2;
    }
    if(this.entreeP3 && this.entreeP3 > 0){
      tmp = tmp + this.typeEntrees[3].pleinTarif * this.entreeP3;
    }
    if(this.entreeR3 && this.entreeR3 > 0){
      tmp = tmp + this.typeEntrees[3].tarifReduit * this.entreeR3;
    }
    this.total = tmp;
  }
  backToHome(){
    this.router.navigateByUrl('/home')
  }
  getTotal(){
    return this.total;
  }
  save(){
    debugger;
  }
}
