import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable'
import { Action } from 'rxjs/internal/scheduler/Action';
@Injectable({
  providedIn: 'root'
})
export class InstallationService {
   installations = [{id:"1",label:'Piscine St Charles',code:"stCharles", status: 'En Service',maint:false,evac:false,
  access:[{label:'entrée pricipal'},
  {label:'entrée Bassin 1'},
  {label:'entrée Bassin 2'},
  {label:'tous'}]}
  , {id:"2",label:'Piscine Vallier',code:"vallier",status: 'En Maintenance',maint:false,evac:false,
  access:[{label:'entrée pricipal'},
  {label:'entrée Bassin 1'},
  {label:'entrée Bassin 2'},
  {label:'tous'}]},
  {id:"3",label:'Piscine Desautel',code:"desautel",status: 'En Service',maint:false,evac:false,
  access:[{label:'entrée pricipal'},
  {label:'entrée Bassin 1'},
  {label:'entrée Bassin 2'},
  {label:'tous'}]},
  {id:"4",label:'Piscine Desautel',code:"desautel",status: 'Hors Service',maint:false,evac:false,
  access:[{label:'entrée pricipal'},
  {label:'entrée Bassin 1'},
  {label:'entrée Bassin 2'},
  {label:'tous'}]}];

  constructor(private http: HttpClient) { }

  getInstallations(){
    return this.installations;
  }
  getInstallationsFromBack(){
    //let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/installations';
    let url ='http://server.devarchi.fr:8080/domotic/cxf/admin/api/equipments';
    return this.http.get<any>(url,{withCredentials: true});
  }
  getInstallationByIdFromBack(id: string){
    let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/installations/'+id;
    return this.http.get<any>(url,{withCredentials: true});
  }
  getInstallationById(id : string){
    return this.installations.find(insta => insta.id === id);
  }
  saveInstallation(installation){
    let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/installations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,installation,{headers ,withCredentials: true});
  }
  createInstallation(installation){
    let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/installations';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,installation,{headers , withCredentials: true});
  }
  forceOpen(installation: string){
    let url ='http://server.devarchi.fr:8080/digicode/cxf/app/api/control/forceOpen?digicInstallationId='+installation;
    return this.http.get<any>(url,{withCredentials: true});
  }
  actionOnEquipement(action: string, id: string){
    let url ='http://server.devarchi.fr:8080/domotic/cxf/app/api/equipments/action';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,{'action':action, 'value':action,'equipmentId':id},{headers , withCredentials: true});
  }
  
  delete(id: string){
    //To be implemented
    return null;
  }
}
