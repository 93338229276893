import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'app-mes-abonnement',
  templateUrl: './mes-abonnement.component.html',
  styleUrls: ['./mes-abonnement.component.css']
})
export class MesAbonnementComponent implements OnInit {

  constructor() { }
  model: any = {};
  // res: any;
  loading = false;
  gridOptions;
  givenInstallation;
  rowData: any;
  rowDataFiltred;
  columnDefs = [
    { headerName: 'Piscine', field: 'piscine' },
    { headerName: 'Numero de Ticket', field: 'ticketCode' },
    {
      headerName: 'Date D\'achat', field: 'date_achat'
    },
    {
      headerName: 'utilisé', field: 'date_validation'
    },
    { headerName: 'type', field: 'type', width: 160 }
  ];

  ngOnInit() {
    this.loadLogs();
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      context: {
        componentParent: this
      },
      enableColResize: true
    };
  }
  loadLogs() {
    this.loading = true;
    this.rowData = 
    [
    {piscine:'Vallier - 4e', ticketCode:'10332201',date_achat:'10/08/2018 10h05',date_validation:'15/08/2018 10h05',type:'ANNUEL P.T'},
    {piscine:'Bonneveine - 8e', ticketCode:'3132017',date_achat:'01/01/2017 11h05',date_validation:'31/12/2017 00h00',type:'ANNUEL P.T'},
    ]
  }
  filter() {
    let flt =this.model.filter;
    if (flt) {
      this.rowDataFiltred = this.rowData.filter(function (element) {
        for (let value of Object.values(element)) {
          var str: String= new String(value).toLocaleUpperCase( );
          if(value !=null){
              if(str.indexOf(flt.toLocaleUpperCase( )) >= 0)return element;
            
          }
        }
        

      })
    } else {
      this.rowDataFiltred = null;
    }

  }
}
