﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
@Injectable()
export class AuthenticationService {
    redirectUrl: string;
    
    constructor(private http: HttpClient, private router: Router,
      private alertService: AlertService) { }
    isLoggedIn = false;
     //url = "http://server.devarchi.fr:8080/digicode/";
     url = "http://server.devarchi.fr:8080/domotic/"
    login(username: string, password: string,nextUrl: string) {
      //let url = "http://server.devarchi.fr:8080/digicode/login";
      let url = this.url+"cxf/app/login"
        if (password && username) {
            url += "?username=" + username;
            url += "&password=" + password;
        }
        debugger;
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        
        this.http.post(url, null,{ headers,withCredentials: true}).subscribe(
            data => {
            localStorage.setItem('currentUser',JSON.stringify(data['user']));
             if(!(data['user'].login == 'admin' || data['user'].role == 'ROLE_ADMIN') && nextUrl == '/home'){
               nextUrl = '/Achats';
             }
            this.router.navigate([nextUrl]);
          },
          error => {
            let msg = "L'identifiant ou le mot de passe est incorrect";
              console.log(JSON.stringify(error));
            this.alertService.error(msg);
            
          });

    }

    logout() {
        // remove user from local storage to log user out
        //let url ="http://server.devarchi.fr:8080/digicode/logout";
        let url =this.url+"cxf/app/logout"
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.http.post(url, null,{ headers,observe:"response"}).subscribe(
            data => {
            let res = data;
            localStorage.removeItem('currentUser');
            this.isLoggedIn = false;
            this.router.navigate(['/home']);
          },
          error => {
            
            localStorage.removeItem('currentUser');
            this.isLoggedIn = false;
            //return callback && callback(true);
            this.router.navigate(['/login']);
          }
        );
        
    }
    passwordLost(login: string){
      //
    }
    static getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
}
