import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services';
import { AccessService } from '../services/access.service';

@Component({
  selector: 'app-key-use',
  templateUrl: './key-use.component.html',
  styleUrls: ['./key-use.component.css']
})
export class KeyUseComponent implements OnInit {

  model: any = {};
  checkResponse: boolean;
  loading = false;
  returnUrl: string;
  constructor(public accessService: AccessService,
    private alertService: AlertService) { }

  ngOnInit() {
  }
  use(){//consume Btn
    this.loading = true;
   
    this.accessService.useAccess(this.model.accessKey).subscribe(
      data => {
        this.checkResponse = (data == true);
        if(data == true){
          this.alertService.success('Code d\'accès révoqué: '+this.model.accessKey);
        }else{
          this.alertService.error('Code d\'accès invalide: '+this.model.accessKey);
        }
        
      },
      error => {
        this.alertService.error('erreur lors du traitement: '+error);
      },
    ()=>{
      this.loading = false;
    });
  }

}
