import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }
    get currentUser() {
      
    return AuthenticationService.getCurrentUser();
    }
    get userConnected(): boolean {
      return !!AuthenticationService.getCurrentUser();
  };

  get userAdmin(): boolean {
    let user = AuthenticationService.getCurrentUser();
    return !!user && (user.login == 'admin' || user.role == 'ROLE_ADMIN');
};
get userNotAdmin(): boolean {
  let user = AuthenticationService.getCurrentUser();
  return !!user && !(user.login == 'admin' || user.role == 'ROLE_ADMIN');
};
  
  logOut(){
    this.authenticationService.logout();
}
}
