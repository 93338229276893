import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable'
@Injectable({
  providedIn: 'root'
})
export class AccessService {
  url = "http://server.devarchi.fr:8080/domotic/"
  private appUrlPart = '/app/api/accessCode/';
  private deviceUrlPart = '/device/api/accessCode/'
  constructor(private http: HttpClient) { }

generateAccess(dateDebut:string, dateFin:string, installationsId: Array<any>,uniqueUsage: boolean,reference?:string,accessCodepersonalise?:string) {
    //let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode/generateAccess?';
    let url = this.url+"cxf/app/api/accessCode/generateAccess?";
    if(reference != undefined){
      url = url+"reference="+reference;
    }
    if(dateDebut != null){
       if(reference != undefined){
         url += '&';
       }

      url = url+"startEffect="+dateDebut;
    }
    if(dateFin != null){
      url = url+"&endEffect="+dateFin;
    }
    if(installationsId != null){
      installationsId.forEach(element => {
        url = url+"&installations="+element
      });
    }
    if(uniqueUsage !=null){
      url= url+"&uniqueUsage="+uniqueUsage;
    }
    if(accessCodepersonalise !=null){
      url= url+"&accessCode="+accessCodepersonalise;
    }
    return this.http.get<any>(url,{withCredentials: true});
}
generateEmergencyAccess(installationsId: Array<any>,reference?:string) {
  let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode/emergencyCode/reload?';
 
  if(reference != undefined)url = url+"reference="+reference;
  if(installationsId != undefined){
    if(reference != undefined){
      url += "&";
    }
    installationsId.forEach((element,i) => {
      if(i == 0){
        url = url+"installations="+element;
      }else{
        url = url+"&installations="+element;
      }
      
    });
  }
  return this.http.get<any>(url,{withCredentials: true});
}
deleteAccess(id){
  let url='http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode/'+id.trim();
  return this.http.delete<any>(url,{withCredentials: true});
}
//use Access Key.
// return boolean.
useAccess(accessCode : string){
  //Obsolete 
return null; //this.http.get<any>('http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode/revoke?accessCode='+accessCode);
}
checkAccess(accessCode : string, installationId){
  let url ='http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode/validateAccess?accessCode='+accessCode+'&installationId='+installationId;
  return this.http.get<any>(url,{withCredentials: true});
}
getAccesLogs(){
  let url= 'http://server.devarchi.fr:8080/digicode/cxf/app/api/auditTrail';
  return this.http.get<any>( url,{withCredentials: true});
}
getAllAcces(){
  let url ='http://server.devarchi.fr:8080/digicode/cxf/app/api/accessCode';
  return this.http.get<any>(url,{withCredentials: true});
}
}
