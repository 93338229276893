import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessService } from '../services/access.service';
import { AlertService } from '../services';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { LocalCalendar } from '../_models/localCalendar';
import { InstallationService } from '../services/installation.service';
//import { ModalComponent } from '../ng2-bs4-modal/components/modal';

@Component({
  selector: 'app-keygenerator',
  templateUrl: './keygenerator.component.html',
  styleUrls: ['./keygenerator.component.css']
  //,encapsulation: ViewEncapsulation.Emulated
})
export class KeygeneratorComponent implements OnInit {

  constructor(public accessService: AccessService,
    public alertService: AlertService,
    public installationService: InstallationService) {
  }
  model: any = {};
  accessKey: string;
  loading: boolean;
  emAccessKey: string;
  installations;
  selectedInstallations = [];
  localCal = LocalCalendar.fr;
  datepickerStart;
  datepickerEnd;
  minDateValue = new Date();
  dateS = null;
  dateE = null;
  uniqueUsage ="no";
  displayModal= 'none';
  accessKeypersonalise;
  ngOnInit() {
    this.installations = this.installationService.getInstallationsFromBack().subscribe(
      data => {
      this.installations = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
      
    },
  ()=>{
    
  });
  }
  validate(form: NgForm) {
    var val = form.value;
    var error: string = '';
    if (this.uniqueUsage == "yes") {
      if ((val.datepickerStart == undefined || val.datepickerStart == null)) {
        if (error != '') error = error.concat(', ');
        error = error.concat('Saisir la date et l\'heure de debut');
      }
      else {
        this.dateS = moment(this.datepickerStart.getTime());
      }
      if ((val.datepickerEnd == undefined || val.datepickerEnd == null)) {
        if (error != '') error = error.concat(', ');
        error = error.concat('Saisir la date et l\'heure de fin');
      }
      else {
        this.dateE = moment(this.datepickerEnd.getTime());
      }

      if (this.dateE && this.dateS && this.dateS.isAfter(this.dateE)) {
        if (error != '') error = error.concat(', ');
        error = error.concat('Incoherence dans les dates saisies');
      }
    }
    /*
     *  TODO re enable this control but allow to check box (no contraint date validation) and set automatically current date for start and another date very far for end date
     */
    if (error != '') {
      this.alertService.error(error, false);
      this.loading = false;
      return false;
    }
    return true;
  }
  generateAccess(f: NgForm) {
    let reference = f.value.reference;
    let accessKeyper = f.value.accessKeypersonalise;
    let selectedInstall = this.selectedInstallations.map(el => el.id);
    if (this.validate(f)) {
      debugger;
      this.accessService.generateAccess(
        this.uniqueUsage == 'yes' && this.dateS != null ? this.dateS.format("DD/MM/YYYY HH:mm:ss") : null,
        this.uniqueUsage == 'yes' && this.dateE != null ? this.dateE.format("DD/MM/YYYY HH:mm:ss") : null,
        selectedInstall,this.uniqueUsage ==='yes'? true:false, reference, accessKeyper).subscribe(
          data => {
            debugger;
            this.accessKey = data;
             this.displayModal= 'block';
          },
          error => {
            debugger;
            let msg;
            if(error.error.message){
              msg = error.error.message;
            }else if(error.message){
              msg =error.message;
            }else{
              console.log("erreur :");
              console.log(JSON.parse(error));
              msg = "erreur Non parsable, ouvez la console";
            }
            this.alertService.error(msg);
            this.loading = false;
          });
    }
    
  }
  closeModalDialog(){
    this.displayModal='none'; //set none css after close dialog
   }
  //datepicker methods
  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }
}
