import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }
  get userAdmin(): boolean {
    let user = AuthenticationService.getCurrentUser();
    return !!user && (user.login == 'admin' || user.role == 'ROLE_ADMIN');
};
goto(link: string){
this.router.navigateByUrl(link);
}
}
