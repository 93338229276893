import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { LocalCalendar } from '../_models/localCalendar';

@Component({
  selector: 'app-tarif',
  templateUrl: './tarif.component.html',
  styleUrls: ['./tarif.component.css']
})
export class TarifComponent implements OnInit {

  constructor() { }
  piscines;
  selectedPiscine;
  typeReservations;
  typeEntrees;


  
  model: NgModel;
  total = 0;
  entreeP0 = 0;
  entreeR0 = 0;
  entreeP1 = 3;
  entreeR1 = 1.5;
  entreeP2 = 20;
  entreeR2 = 10;
  entreeP3 = 161;
  entreeR3 = 84;
  ngOnInit() {
    this.piscines = [
      {id:1,label:'Saint-Charles - 1er'},
  {id:2,label:'Vallier - 4e'},
  {id:3,label:'Bonneveine - 8e'},
  {id:4,label:'Pointe Rouge - 8e'},
  {id:5,label:'Desautel - 9e'},
  {id:6,label:'Pont-de-Vivaux - 10e'},
  {id:7,label:'La Granière - 11e'},
  {id:8,label:'Bombardière - 12e'},
  {id:9,label:'Louis Armand - 12e'},
  {id:10,label:'Frais Vallon - 13e'},
  {id:11,label:'Saint Joseph - 14e'},
  {id:12,label:'Busserine - 14e'},
  {id:13,label:'La Martine - 15e'},
  {id:14,label:'La Castellane - 16e'},
  {id:15,label:'Magnac - 8e'}];
  this.typeEntrees=[
    {id:1,label:'ENTRÉE A L’UNITÉ',pleinTarif:3,tarifReduit:1.5},
    {id:2,label:'CARTE 10 ENTRÉES',pleinTarif:20,tarifReduit:10},
    {id:3,label:'ABONNEMENT ANNUEL',pleinTarif:161,tarifReduit:84},
    {id:4,label:'GRATUITÉ',pleinTarif:0,tarifReduit:0}
  ];
  this.typeReservations=[
    {id:1,label:'ENTRÉES'},
    {id:2,label:'LEÇONS'}
  ];
  
  }
}
