
export class BooleanFormater {
    static booleanFormatter(params) {
        if (params.value) {
          return "Oui";
        }
        else {
          return "Non";
        }
        ;
      }
}
