import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services';
import { InstallationService } from '../services/installation.service';
import { AccessService } from '../services/access.service';
import * as moment from 'moment';
import { TimeFormater } from '../helpers/time-formater';
import { BooleanFormater } from '../helpers/boolean-formater';
import { CellEditionComponent } from '../cell-edition/cell-edition.component';
import { GridOptions } from 'ag-grid';
@Component({
  selector: 'app-keygestion',
  templateUrl: './keygestion.component.html',
  styleUrls: ['./keygestion.component.css']
})
export class KeygestionComponent implements OnInit {

  constructor(public accessService: AccessService,
    private alertService: AlertService) { }
  loading = false;
  gridOptions;
  rowData: any;
  columnDefs = [
    {
      headerName: '', field: 'rowNumbre',
      autoHeight: true,
      cellRendererFramework: CellEditionComponent
    },
    { headerName: 'code d\'accès', field: 'accessCode' },
    { headerName: 'reference', field: 'reference' },
    { headerName: 'type', field: 'type' },
    { headerName: 'installation', field: 'installationLabel' },
    {
      headerName: 'date Creation', field: 'date_creation', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {
      headerName: 'date utilisation', field: 'lastConsumedDate', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {
      headerName: 'usage', field: 'uniqueUsage',
      valueFormatter: BooleanFormater.booleanFormatter,
      filter: 'agTextColumnFilter', width: 160
    },
    {
      headerName: 'date debut', field: 'dateStartEffect', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {
      headerName: 'date fin', field: 'dateEndEffect', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },

  ];
  ngOnInit() {
    this.loadAccess();
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      context: {
        componentParent: this
      },
      enableColResize: true
    };

  }

  loadAccess() {
    this.loading = true;
    debugger;
    this.accessService.getAllAcces().subscribe(
      data => {
        debugger;
        this.rowData = [];
        data.forEach((ligne, index) => {
          let item = ligne;
          item['rowNumbre'] = index;
          this.rowData.push(item);
        })
        debugger;
      },
      error => {
        this.alertService.error(error);

      },
      () => {
        this.loading = false;
      });
  }
  public deleteline(ligneData) {
    debugger;
    this.accessService.deleteAccess(ligneData.id).subscribe(
      data => {
        debugger;
        this.loadAccess();
        this.alertService.success("Code d'accès supprimé:" + ligneData.accessCode);
      },
      error => {
        let msg
        if (error.error.message) {
          msg = error.error.message;
        } else if (error.message) {
          msg = error.message;
        } else {
          console.log("erreur :");
          console.log(JSON.parse(error));
          msg = "erreur Non parsable, ouvez la console";
        }
        this.alertService.error(msg);

      },
      () => {
        this.loading = false;
      });
  }
}
