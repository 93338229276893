import { Component, OnInit, Input } from '@angular/core';
import { AccessService } from '../services/access.service';
import { AlertService } from '../services';
import { KeysPipe } from '../helpers/keys.pipe'
import * as moment from 'moment';
import { InstallationService } from '../services/installation.service';
import { ActivatedRoute } from '@angular/router';
import { TimeFormater } from '../helpers/time-formater';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'app-key-check',
  templateUrl: './key-logs.component.html',
  styleUrls: ['./key-logs.component.css']
})
export class KeyLogsComponent implements OnInit {
  model: any = {};
  // res: any;
  loading = false;
  gridOptions;
  givenInstallation;
  rowData: any;
  rowDataFiltred;
  columnDefs = [
    { headerName: 'code d\'accès', field: 'accessCode' },
    {
      headerName: 'Date', field: 'date_creation', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    { headerName: 'Evènement', field: 'type', width: 160 },
    {
      headerName: 'Accès', field: 'deviceId',
      filter: 'agTextColumnFilter'
    },
    { headerName: 'Référence', field: 'reference' }
    
  ];

  constructor(public accessService: AccessService,
    public installationService: InstallationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private keysPipe: KeysPipe) { }

  ngOnInit() {
    debugger;
    this.loadLogs();
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      context: {
        componentParent: this
      },
      enableColResize: true
    };
  }
  filter() {
    let flt =this.model.filter;
    if (flt) {
      this.rowDataFiltred = this.rowData.filter(function (element) {
        for (let value of Object.values(element)) {
          var str: String= new String(value).toLocaleUpperCase( );
          if(value !=null){
              if(str.indexOf(flt.toLocaleUpperCase( )) >= 0)return element;
            
          }
        }
        

      })
    } else {
      this.rowDataFiltred = null;
    }

  }
  loadLogs() {
    this.loading = true;
    //si on a un id en param on charge les log de l'instation correspondante, sinon on charge tout les logs
    let accessId = this.route.snapshot.paramMap.get('accessId');
    this.givenInstallation = this.installationService.getInstallationById(accessId);
    this.accessService.getAccesLogs().subscribe(
      data => {
        this.rowData = (this.givenInstallation) ? data.filter(el => el.name == this.givenInstallation.name) : data;
        debugger;
      },
      error => {
        this.alertService.error(error);

      },
      () => {
        this.loading = false;
      });
  }

}
