import { Component } from '@angular/core';
import * as moment from 'moment'; // add this 1 of 4
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  // currentUser: User;
   get currentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'))
  };

   get userConnected(): boolean {  
    return (localStorage.getItem('currentUser'))?true:false;
  };
}
