import { Component, OnInit } from '@angular/core';
import { AccessService } from '../services/access.service';
import { AlertService } from '../services';
import { NotificationService } from '../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TimeFormater } from '../helpers/time-formater';
import { InstallationService } from '../services/installation.service';
import { BooleanFormater } from '../helpers/boolean-formater';
import { CellEditionComponent } from '../cell-edition/cell-edition.component';
import { GridOptions } from 'ag-grid';
@Component({
  selector: 'app-key-notifications',
  templateUrl: './key-notifications.component.html',
  styleUrls: ['./key-notifications.component.css']
})
export class KeyNotificationsComponent implements OnInit {
  model: any = {};
  checkResponse: boolean;
  loading = false;
  returnUrl: string;
  installId;
  givenInstallation;
  rowData;
  rowDataFiltred;
  gridOptions;
  columnDefs = [
    {
      headerName: '', field: 'rowNumbre',
      autoHeight: true,
      cellRendererFramework: CellEditionComponent
    },
    { headerName: 'Canal', field: 'type' },
    { headerName: 'Evenement', field: 'eventType' },
    { headerName: 'Toutes les occurrences', field: 'eachOccurence'
    , valueFormatter: BooleanFormater.booleanFormatter,
    filter: 'agTextColumnFilter'
    },
    { headerName: 'Destinataire', field: 'destValue'},
    {
      headerName: 'Occurrences', field: 'nbNextOccurence',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Nb execution', field: 'nbExecution',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Date de création', field: 'date_creation', valueFormatter: TimeFormater.timeFormatter,
      filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var celM = moment(moment(cellValue).format('DD/MM/YYYY'));
          var filM = moment(moment(filterLocalDateAtMidnight).format('DD/MM/YYYY'));
          // Now that both parameters are Date objects, we can compare
          if (celM.isBefore(filM)) {
            return -1;
          } else if (filM.isBefore(celM)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    }
    
  ];
  constructor(public accessService: AccessService,
    private notifacationService: NotificationService,
    private installationService: InstallationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService) { }

  ngOnInit() {
    this.installId = this.route.snapshot.paramMap.get('id');
    this.loadNotifications();
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      context: {
        componentParent: this
      },
      enableColResize: true
    };
    
  }
  createCotification(){
      this.router.navigateByUrl('/creationNotif')
  }
  
  getInstallationNotifs(installationId){
    let endPointToCall;
    debugger;
    if(installationId){
      endPointToCall = this.notifacationService.notificationsByInstallation(installationId);
    } else {
      endPointToCall = this.notifacationService.notifications();
    }
    endPointToCall.subscribe(
      data => {
        debugger;
        this.rowData = [];
        data.forEach((ligne, index) => {
          let item = ligne;
          item['rowNumbre'] = index;
          this.rowData.push(item);
          
        });
        this.filter();
      
       },
       error => {
        
         this.alertService.error(error);

       },
      () => {
         this.loading = false;
       });
  }
  filter() {
    
    let flt =this.model.filter;
    if (flt) {
      this.rowDataFiltred = this.rowData.filter(function (element) {
        
        for (let value of Object.values(element)) {
          var str: String= new String(value);
          if(value !=null){
              if(str.indexOf(flt) >= 0)return element;
            
          }
        }
        

      })
    } else {
      this.rowDataFiltred = null;
    }

  }
  loadNotifications(){
    if(this.installId){
      this.givenInstallation = this.installationService.getInstallationByIdFromBack(this.installId).subscribe(
              data =>{
                this.givenInstallation= data;
              },
              error=>{
                this.alertService.error(error);
              },
              ()=>{
                //this.usubscribe();
              }
            );
      this.getInstallationNotifs(this.installId);
    } else {
      this.getInstallationNotifs(null);
    }
  }
  public deleteline(ligneData) {
    debugger;
    this.notifacationService.deleteNotification(ligneData.id).subscribe(
      data => {
        debugger;
        this.loadNotifications();
        this.alertService.success("Notification supprimé");
      },
      error => {
        let msg
        if (error.error.message) {
          msg = error.error.message;
        } else if (error.message) {
          msg = error.message;
        } else {
          console.log("erreur :");
          console.log(JSON.parse(error));
          msg = "erreur Non parsable, ouvez la console";
        }
        this.alertService.error(msg);

      },
      () => {
        this.loading = false;
      });
  }

}
