import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent }    from './not-found.component';
import { CanDeactivateGuard }       from './can-deactivate-guard.service';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import {HomeComponent} from './home/home.component';
import {LoginFComponent} from './login-f/login-f.component';
import {KeygeneratorComponent} from './keygenerator/keygenerator.component';
import { KeyUseComponent } from './key-revok/key-use.component';
import { KeyCheckComponent } from './key-check/key-check.component';
import { KeyInstallationsComponent } from './key-installations/key-installations.component';
import { KeyLogsComponent } from './key-logs/key-logs.component';
import { KeyNotificationsComponent } from './key-notifications/key-notifications.component';
import { UrgentKeygenertorComponent } from './urgent-keygenertor/urgent-keygenertor.component';
import { ProfilComponent } from './profil/profil.component';
import { GestionInstallationComponent } from './gestion-installation/gestion-installation.component';
import { AuthGuard } from './guard/auth.guard';
import { CreationInsatarionComponent } from './creation-insatarion/creation-insatarion.component';
import { CreationNotificatonComponent } from './creation-notificaton/creation-notificaton.component';
import { KeygestionComponent } from './keygestion/keygestion.component';
import { ReservationComponent } from './reservation/reservation.component';
import { MesAchatComponent } from './mes-achat/mes-achat.component';
import { MesAbonnementComponent } from './mes-abonnement/mes-abonnement.component';
import { TarifComponent } from './tarif/tarif.component';
const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { preload: true }
  },
  {
    path: 'Achats',
    component: MesAchatComponent,
    data: { preload: true }
  },
  {
    path: 'tarif',
    component: TarifComponent,
    data: { preload: true }
  },
  {
    path: 'Abonnements',
    component: MesAbonnementComponent,
    data: { preload: true }
  },
  {
    path: 'reservation',
    component: ReservationComponent
  },
  {
    path: 'installations',
    component: KeyInstallationsComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'gestionInstallation/:id',
    component: GestionInstallationComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'notifInstallation/:id',
    component: KeyNotificationsComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'createInstallation',
    component: CreationInsatarionComponent,
    canLoad: [AuthGuard]
  },
  {
    path: 'notifications',
    component: KeyNotificationsComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'accessLogs/:accessId',
    component: KeyLogsComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'accessLogs',
    component: KeyLogsComponent
    , canLoad: [AuthGuard]
  },
  {
    path: 'generate',
    component: KeygeneratorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'ugenerate',
    component: UrgentKeygenertorComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'checkAccecs',
    component: KeyCheckComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'creationNotif',
    component: CreationNotificatonComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'editAccess',
    component: KeygestionComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginFComponent,
    data: { preload: true }
  },
  {
    path: 'profil',
    component: ProfilComponent,
    data: { preload: true }
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategy,

      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuard,
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }
