import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../services';
import { InstallationService } from '../services/installation.service';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { LocalCalendar } from '../_models/localCalendar';
@Component({
  selector: 'app-gestion-installation',
  templateUrl: './gestion-installation.component.html',
  styleUrls: ['./gestion-installation.component.css']
})
export class GestionInstallationComponent implements OnInit {
  currentInstallation;
  id;
  displayModal= 'none';
  modalTitle;
  modalMsg;

  localCal = LocalCalendar.fr;
  datepickerStart;
  datepickerEnd;
  minDateValue = new Date();
  dateS = null;
  dateE = null;
  
  evacSwitch = false;
  maintSwitch = false;
  openSwitch = false;

  constructor( public installationService: InstallationService,
    private alertService: AlertService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    debugger;
    this.currentInstallation = this.installationService.getInstallationById(this.id);
  }
  actionEvacOnEquipement(){
    // OF SUR le 1 
    // 1s ON sur 1 et ON sur le 3  
    //pour desactiver appel off sur le 3 
    debugger;
    if(this.evacSwitch){
      //activation Evac
      //OFF sur le 1
      this.installationService.actionOnEquipement('OFF', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
      data =>{
        setTimeout( () => { 
          this.installationService.actionOnEquipement('ON', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
            data =>{
              //this.alertService.success("Commande envoyée");
              this.installationService.actionOnEquipement('ON', 'f78fb324-7ec6-4e46-8501-8359efb23681').subscribe(
                data =>{
                  this.alertService.success("Mode evacuation Activé");
                },
                error=>{
                  this.alertService.error(error);
                  this.evacSwitch = !this.evacSwitch;
                });
            },
            error=>{
              this.alertService.error(error);
              this.evacSwitch = !this.evacSwitch;
            });
        }, 1000 );
        
      },
      error=>{
        this.alertService.error(error);
        this.evacSwitch = !this.evacSwitch;
      });
    } else {// desactivation du mode evac
      this.installationService.actionOnEquipement('OFF', 'f78fb324-7ec6-4e46-8501-8359efb23681').subscribe(
        data =>{
          this.alertService.success("Mode evacuation désactivé");
        },
        error=>{
          this.alertService.error(error);
          this.evacSwitch = !this.evacSwitch;
        });
    }
    
  }
  actionOpenOnEquipement(){
    // "f6442672-5ec1-4fff-abfa-c9558dedad73" -> OFF 
    // 1s ON
    debugger;
    this.installationService.actionOnEquipement('OFF', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
      data =>{
        setTimeout( () => { 
          this.installationService.actionOnEquipement('ON', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
            data =>{
              this.alertService.success("Mode Ouverture effectuée");
            },
            error=>{
              this.alertService.error(error);
            });
        }, 1000 );
      },
      error=>{
        this.alertService.error(error);
      });
  }
  actionMainOnEquipement() {
    // bc57941b-26c4-4d37-853f-9d8e2aa446ce ON sur le 4em
    // pour le desactiver un OFF sur le 4 em 
    debugger;
    if(this.maintSwitch){
      //activation
      this.installationService.actionOnEquipement('ON', 'bc57941b-26c4-4d37-853f-9d8e2aa446ce').subscribe(
        data =>{
          debugger;
          this.alertService.success("Mode Maintenance Activé");
        },
        error=>{
          this.alertService.error(error);
          this.maintSwitch = !this.maintSwitch
        });
    } else {
      this.installationService.actionOnEquipement('OFF', 'bc57941b-26c4-4d37-853f-9d8e2aa446ce').subscribe(
        data =>{
          debugger;
          this.alertService.success("Mode Maintenance désactivé");
        },
        error=>{
          this.alertService.error(error);
          this.maintSwitch = !this.maintSwitch
        });
    }
    
  }

  save(form){
    this.installationService.saveInstallation(this.currentInstallation).subscribe(
      data =>{
        debugger;
        this.alertService.success("Modificaion enregistrée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      }
    );
  }

  forceOpen(){
    debugger;
    this.installationService.forceOpen(this.currentInstallation.id).subscribe(
      data =>{
        debugger;
        this.alertService.success("Accès forcé pour l'installation "+this.currentInstallation.label);
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      }
    );
  }
  closeModalDialog(){
    this.displayModal='none'; //set none css after close dialog
   }
}

async function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}