import { Component, OnInit } from '@angular/core';
import { InstallationService } from '../services/installation.service';
import { AlertService, AuthenticationService } from '../services';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-creation-notificaton',
  templateUrl: './creation-notificaton.component.html',
  styleUrls: ['./creation-notificaton.component.css']
})
export class CreationNotificatonComponent implements OnInit {
  model: any = {};

  type= 'SMS';
  phone;
  events= [{label:'ACCESS_SUCCESS',value:'ACCESS_SUCCESS'}, {label:'ACCESS_FAILED',value:'ACCESS_FAILED'}, {label:'ALREADY_CONSUMED',value:'ALREADY_CONSUMED'},
  {label:'REVOKE',value:'REVOKE'},{label:'GENERATE',value:'GENERATE'}, {label:'EMERGENCY',value:'EMERGENCY'}];
  selectedEvent :any;
  installations;
  selectedInstallations = [];
  eachOccurence: boolean = false;
  nbNextOccurence;
  nbExecution;
  destinataire;
  constructor(private installationService: InstallationService,
    private notificationService: NotificationService, private  alertService: AlertService) { }

  ngOnInit() {
    this.installationService.getInstallationsFromBack().subscribe(
      data => {
      this.installations = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
      
    },
  ()=>{
    
  });
  }
  save(form){
    debugger;
    let selectedInstall = this.selectedInstallations.map(el => el.id);
    let selectedEvent = this.selectedEvent.value;
    let notifConf={};
    let user = AuthenticationService.getCurrentUser();
    notifConf['userId'] = user?user.id:null;
    notifConf['type'] = this.type;
    notifConf['eventType'] = selectedEvent;
    notifConf['eachOccurence'] =this.eachOccurence;
    notifConf['nbNextOccurence'] = this.nbNextOccurence;
    notifConf['installationIds'] = selectedInstall;
    notifConf['destValue'] = this.destinataire;
    this.notificationService.createNotification(notifConf).subscribe(
      data => {
        let tmp = data;
        // this.modalTitle="Creation de notification"
        // this.modalMsg = "Notification enregistrée"
        // this.displayModal= 'block';
        this.alertService.success("Notification enregistrée ")
      },
      error => {
        let msg
        if(error.error.message){
          msg = error.error.message;
        }else if(error.message){
          msg =error.message;
        }else{
          console.log("erreur :");
          console.log(JSON.parse(error));
          msg = "erreur Non parsable, ouvez la console";
        }
        this.alertService.error(msg);
        
      },
    ()=>{
      
    });
  }

}