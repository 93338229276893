import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessService } from '../services/access.service';
import { AlertService } from '../services';
import { NgForm } from '../../../node_modules/@angular/forms';
import { LocalCalendar } from '../_models/localCalendar';
import { InstallationService } from '../services/installation.service';
//import {ModalComponent} from '../ng2-bs4-modal/components/modal';

@Component({
  selector: 'app-urgent-keygenertor',
  templateUrl: './urgent-keygenertor.component.html',
  styleUrls: ['./urgent-keygenertor.component.css']
})
export class UrgentKeygenertorComponent implements OnInit {

  constructor(public accessService: AccessService,
    public alertService: AlertService,
    public installationService: InstallationService) {
  }
  displayModal;
  accessKey: string;
  loading: boolean;
  emAccessKey: string;
  installations;
  selectedInstallations =[];
  ngOnInit() {
    this.installations = this.installationService.getInstallationsFromBack().subscribe(data => {
      this.installations = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
      
    },
  ()=>{
    
  });
  
  }

  generateEmAccess(f: NgForm) {
    let reference = f.value.eReference;
    let selectedInstall = this.selectedInstallations.map(el => el.id);
    this.accessService.generateEmergencyAccess(selectedInstall,reference).subscribe(
      data => {
        this.emAccessKey = data;
        this.displayModal = 'block';
        //this.alertService.success('code généré avec succès: '+data);
      },
      error => {
        let msg
        if(error.error.message){
          msg = error.error.message;
        }else if(error.message){
          msg =error.message;
        }else{
          console.log("erreur :");
          console.log(JSON.parse(error));
          msg = "erreur Non parsable, ouvez la console";
        }
        this.alertService.error(msg);
     },
    ()=>{
      this.loading =false;
    });
}
closeModalDialog(){
  this.displayModal='none'; //set none css after close dialog
 }
}
