import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[requiredIf]',
  providers: [
    {provide: NG_VALIDATORS,useExisting:RequiredIfDirectiveDirective, multi: true}
]
})
export class RequiredIfDirectiveDirective {
  @Input("requiredIf")
  requiredIf: boolean;

  validate(c:AbstractControl) {

     let value = c.value;
      if ((value == null || value == undefined || value == "") && this.requiredIf) {
              return {
                  requiredIf: {condition:this.requiredIf}
              };
      }
      return null;
  }
}
