import { Component, OnInit } from '@angular/core';
import { InstallationService } from '../services/installation.service';
import { AlertService } from '../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creation-insatarion',
  templateUrl: './creation-insatarion.component.html',
  styleUrls: ['./creation-insatarion.component.css']
})
export class CreationInsatarionComponent implements OnInit {
  currentInstallation ={};
  loading = false;
  displayModal;
  modalTitle;
  modalMsg;
  constructor(public installationService: InstallationService,
    public alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
  }
  backToInstallations(){
    this.router.navigateByUrl('/installations')
  }
  save(form){
    this.installationService.createInstallation(this.currentInstallation).subscribe(
      data => {
        let tmp = data;
        debugger;
        this.modalTitle="Nouvelle d'installation"
        this.modalMsg = "Installation enregistrée"
        this.displayModal = 'block';
      },
      error => {
        let msg
        if(error.error.message){
          msg = error.error.message;
        }else if(error.message){
          msg =error.message;
        }else{
          console.log("erreur :");
          console.log(JSON.parse(error));
          msg = "erreur Non parsable, ouvez la console";
        }
        this.alertService.error(msg);
        
      },
    ()=>{
      
    });
  }
  closeModalDialog(){
    this.displayModal='none'; //set none css after close dialog
   }
}
