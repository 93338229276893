import { NgModule }       from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Router } from '@angular/router';
import { AppComponent }            from './app.component';
import { AppRoutingModule }        from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PageNotFoundComponent }   from './not-found.component';
import {HomeModule} from './home/home.module';
import { HttpClientModule} from '@angular/common/http';
import {AlertService} from './services/alert.service';
import {AuthenticationService} from './services/authentication.service';
import {UserService} from './services/user.service';
import { LoginFComponent } from './login-f/login-f.component';
import { KeygeneratorComponent } from './keygenerator/keygenerator.component';
import { AlertComponent } from './directives/alert/alert.component';
import { KeyUseComponent } from './key-revok/key-use.component';
import { KeyCheckComponent } from './key-check/key-check.component';
//import { KeyInstallationsComponent } from './key-installations/key-installations.component';
import { KeyLogsComponent } from './key-logs/key-logs.component';
import { KeyNotificationsComponent } from './key-notifications/key-notifications.component';
import {NgbModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { RequiredIfDirectiveDirective } from './helpers/required-if-directive.directive';
import { KeysPipe } from './helpers/keys.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { NavBarComponent } from './nav-bar/nav-bar.component';
//PrimeNg components
import { CalendarModule } from '../../node_modules/primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputMaskModule} from 'primeng/inputmask';
import { UrgentKeygenertorComponent } from './urgent-keygenertor/urgent-keygenertor.component';
import { KeyInstallationsComponent } from './key-installations/key-installations.component';
import { ProfilComponent } from './profil/profil.component';
import { GestionInstallationComponent } from './gestion-installation/gestion-installation.component';
import { KeygestionComponent } from './keygestion/keygestion.component';
import { AuthGuard } from './guard/auth.guard';
import { CreationInsatarionComponent } from './creation-insatarion/creation-insatarion.component';
import { CreationNotificatonComponent } from './creation-notificaton/creation-notificaton.component';
import { CellEditionComponent } from './cell-edition/cell-edition.component';
import { ReservationComponent } from './reservation/reservation.component';
import { MesAchatComponent } from './mes-achat/mes-achat.component';
import { MesAbonnementComponent } from './mes-abonnement/mes-abonnement.component';
import { TarifComponent } from './tarif/tarif.component';

//import { ModalModule } from './ng2-bs4-modal/ng2-bs4-modal.module';
@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HomeModule,
    AppRoutingModule,
    CalendarModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([CellEditionComponent]),
    NgbModule.forRoot(),
    NgSelectModule
    //,ModalModule
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginFComponent,
    AlertComponent,
    KeygeneratorComponent,
    KeyUseComponent,
    KeyCheckComponent,
    KeyLogsComponent,
    KeyNotificationsComponent,
    RequiredIfDirectiveDirective,
    KeysPipe,
    NavBarComponent,
    UrgentKeygenertorComponent,
    KeyInstallationsComponent,
    ProfilComponent,
    GestionInstallationComponent,
    KeygestionComponent,
    CreationInsatarionComponent,
    CreationNotificatonComponent,
    CellEditionComponent,
    ReservationComponent,
    MesAchatComponent,
    MesAbonnementComponent,
    TarifComponent,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    KeysPipe
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
