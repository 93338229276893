import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {AlertService} from '../services/alert.service';

@Component({
  selector: 'app-login-f',
  templateUrl: './login-f.component.html',
  styleUrls: ['./login-f.component.css']
})
export class LoginFComponent implements OnInit {

  model: any = {};
  loading = false;
  nextUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }

  ngOnInit() {
    // get return url from route parameters or default to '/generate'
    this.nextUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    if(!!AuthenticationService.getCurrentUser()){
      this.goTonextroute();
    }
  }

  login() {
      this.loading = true;
      this.authenticationService.login(this.model.username,this.model.password,this.nextUrl);
    }
    forgot(){
      this.loading = true;
      this.authenticationService.passwordLost(this.model.rusername);

    }
  goTonextroute(){
        this.loading = false;
  }
}
