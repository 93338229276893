﻿import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route} from '@angular/router';
//import { AuthService } from '../auth.service';
import { AuthenticationService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private authService: AuthenticationService) { }

    /**
     * TODO: ajouter le filtre sur les user Role pour l'activation des route.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (AuthGuard.checkLogin()) {
            return true;
        }

        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
  canLoad(route: Route): boolean {
    
    let url = `/${route.path}`;
    if (AuthGuard.checkLogin()) {
      // logged in so return true
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: url }});
      return false;
    }
  }
  static checkLogin(){
    debugger
    return !!localStorage.getItem('currentUser');

  }
}
