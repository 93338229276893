import { Component, OnInit } from '@angular/core';
import { AccessService } from '../services/access.service';
import { AlertService } from '../services';
import { InstallationService } from '../services/installation.service';

@Component({
  selector: 'app-key-check',
  templateUrl: './key-check.component.html',
  styleUrls: ['./key-check.component.css']
})
export class KeyCheckComponent implements OnInit {
  model: any = {};
  checkResponse: boolean;
  installations;
  selectedInstallation;
  loading = false;
  returnUrl: string;
  constructor(public accessService: AccessService,
    private installationService: InstallationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.installationService.getInstallationsFromBack().subscribe(
      data => {
      this.installations = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
      
    },
  ()=>{
    
  });
  }

  check(){
    this.loading = true;
    debugger;
    let installationId = this.selectedInstallation.id;
    this.accessService.checkAccess(this.model.accessKey,installationId ).subscribe(
      data => {
        this.checkResponse = (data == true);
        if(data == true){
          this.alertService.success('Code d\'accès valide: '+this.model.accessKey);
        }else{
          this.alertService.error('Code d\'accès invalide: '+this.model.accessKey);
        }
        this.loading = false;
      },
      error => {
        this.alertService.error('erreur lors du traitement: '+this.model.accessKey);
        this.loading = false;
      },
    () =>{
      this.loading = false;
    });
  }

}
