import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-cell-edition',
  templateUrl: './cell-edition.component.html',
  styleUrls: ['./cell-edition.component.css']
})
export class CellEditionComponent implements OnInit, ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public deleteline() {
   this.params.context.componentParent.deleteline(this.params.data);
  }

  refresh(): boolean {
      return false;
  }
  constructor() { }

  ngOnInit() {
  }

}
