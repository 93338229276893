import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }
  notifications(){
    //liste des notif
    let url= 'http://server.devarchi.fr:8080/digicode/cxf/app/api/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  createNotification(notification){
    let url = 'http://server.devarchi.fr:8080/digicode/cxf/app/api/notificationConfigs';
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post<any>(url,installation,{headers ,withCredentials: true});
    return this.http.post(url,notification,{headers , responseType: "text", withCredentials: true})
  }
  notificationsByInstallation(id){
    // notif d'install
    //FIXEME: Erreur 500 ?!
    debugger;
    let url ='http://server.devarchi.fr:8080/digicode/cxf/app/api/notificationConfigs/digicInstallation/'+id+'/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  deleteNotification(id){
    debugger;
    let url ='http://server.devarchi.fr:8080/digicode/cxf/app/api/notificationConfigs/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
}
