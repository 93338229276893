import { Component, OnInit } from '@angular/core';
import { InstallationService } from '../services/installation.service';
import { Router } from '@angular/router';
import { AlertService } from '../services';
@Component({
  selector: 'app-key-installations',
  templateUrl: './key-installations.component.html',
  styleUrls: ['./key-installations.component.css']
})
export class KeyInstallationsComponent implements OnInit {

  constructor(public installationService: InstallationService,
    public alertService: AlertService,
    private router: Router) { }
  installations;
  rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;
  
  evacSwitch = false;
  maintSwitch = false;
  openSwitch = false;

  evacSwitch0 = false;
  maintSwitch0 = false;
  openSwitch0 = false;

  evacSwitch1 = false;
  maintSwitch1 = false;
  openSwitch1 = false;
  
  evacSwitch2 = false;
  maintSwitch2 = false;
  openSwitch2 = false;

  ngOnInit() {
    this.installations = this.installationService.getInstallations();
    this.rowData = this.installationService.getInstallations();
    
  }

  
  gerer(installation){
    this.router.navigateByUrl('/gestionInstallation/'+installation.id);
  }
  journal(installation){
    this.router.navigateByUrl('/accessLogs/'+installation.id);
  }
  notification(installation){
    this.router.navigateByUrl('/notifications?installationId='+installation.id);
  }
  createInstallation(){
    this.router.navigateByUrl('/createInstallation');
  }
  forceOpen(installation){
    debugger;
    this.installationService.forceOpen(installation.id).subscribe(
      data =>{
        debugger;
        this.alertService.success("Commande d'ouverture "+ installation.label + " envoyée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      }
    );
  }
  actionEvacOnEquipement(state) {
    // OF SUR le 1 
    // 1s ON sur 1 et ON sur le 3  
    //pour desactiver appel off sur le 3 
    debugger;
    if(state){
      //activation Evac
      //OFF sur le 1
      this.installationService.actionOnEquipement('OFF', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
      data =>{
        setTimeout( () => { 
          this.installationService.actionOnEquipement('ON', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
            data =>{
              //this.alertService.success("Commande envoyée");
              this.installationService.actionOnEquipement('ON', 'f78fb324-7ec6-4e46-8501-8359efb23681').subscribe(
                data =>{
                  this.alertService.success("Mode evacuation Activé");
                },
                error=>{
                  this.alertService.error(error);
                  this.evacSwitch = !this.evacSwitch;
                });
            },
            error=>{
              this.alertService.error(error);
              this.evacSwitch = !this.evacSwitch;
            });
        }, 1000 );
        
      },
      error=>{
        this.alertService.error(error);
        this.evacSwitch = !this.evacSwitch;
      });
    } else {// desactivation du mode evac
      this.installationService.actionOnEquipement('OFF', 'f78fb324-7ec6-4e46-8501-8359efb23681').subscribe(
        data =>{
          this.alertService.success("Mode evacuation désactivé");
        },
        error=>{
          this.alertService.error(error);
          this.evacSwitch = !this.evacSwitch;
        });
    }
    
  }
  actionMainOnEquipement(state) {
    // bc57941b-26c4-4d37-853f-9d8e2aa446ce ON sur le 4em
    // pour le desactiver un OFF sur le 4 em 
    debugger;
    if(state){
      //activation
      this.installationService.actionOnEquipement('ON', 'bc57941b-26c4-4d37-853f-9d8e2aa446ce').subscribe(
        data =>{
          debugger;
          this.alertService.success("Mode Maintenance Activé");
        },
        error=>{
          this.alertService.error(error);
          this.maintSwitch = !this.maintSwitch
        });
    } else {
      this.installationService.actionOnEquipement('OFF', 'bc57941b-26c4-4d37-853f-9d8e2aa446ce').subscribe(
        data =>{
          debugger;
          this.alertService.success("Mode Maintenance désactivé");
        },
        error=>{
          this.alertService.error(error);
          this.maintSwitch = !this.maintSwitch
        });
    }
    
  }
  actionOpenOnEquipement(){
    // "f6442672-5ec1-4fff-abfa-c9558dedad73" -> OFF 
    // 1s ON
    debugger;
    this.installationService.actionOnEquipement('OFF', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
      data =>{
        setTimeout( () => { 
          this.installationService.actionOnEquipement('ON', 'f6442672-5ec1-4fff-abfa-c9558dedad73').subscribe(
            data =>{
              this.alertService.success("Mode Ouverture effectuée");
            },
            error=>{
              this.alertService.error(error);
            });
        }, 1000 );
      },
      error=>{
        this.alertService.error(error);
      });
  }

  actionOnEquipement(action, equipmentId) {
    debugger;
    this.installationService.actionOnEquipement(action, equipmentId).subscribe(
      data =>{
        debugger;
        this.alertService.success("Commande envoyée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      })
  }

  delete(installation){
    this.installationService.delete(installation.id);

  }
}
